import { Button, TableRow } from '@mui/material'
import { Role } from 'interfaces'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { format } from 'date-fns'
import { TableCell } from 'components/table'
import { getRoleName, getRoleOuName, getRoleOuShortName } from 'utils'

interface RoleLineProps {
  role: Role
  pid: string
}

const RoleLine = ({ role, pid }: RoleLineProps) => {
  const [t] = useTranslation('common')

  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell align="left">{getRoleName(role)}</TableCell>
      <TableCell align="left">{`${getRoleOuName(role)}${getRoleOuShortName(role)}`}</TableCell>      
      <TableCell component="th" scope="row">
        {role.start_date ? format(role.start_date, 'yyyy-MM-dd') : null} -{' '}
        {format(role.end_date, 'yyyy-MM-dd')}
      </TableCell>
      <TableCell align="left">{role.sponsor_name}</TableCell>
      <TableCell>
        <Button
          variant="contained"
          color="secondary"
          component={Link}
          to={`/sponsor/guest/${pid}/roles/${role.id}`}
        >
          {t('sponsor.choose')}
        </Button>
      </TableCell>
    </TableRow>
  )
}

interface UserRoleLineProps {
  role: Role
}
export const UserRoleLine = ({ role }: UserRoleLineProps) => {
  const [, i18n] = useTranslation('common')
  console.log(role)
  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell align="left">
        {i18n.language === 'en' ? role.name_en : role.name_nb}
      </TableCell>
      <TableCell component="th" scope="row">
        {role.start_date ? format(role.start_date, 'yyyy-MM-dd') : null} -{' '}
        {format(role.end_date, 'yyyy-MM-dd')}
      </TableCell>
      <TableCell align="left">
        {i18n.language === 'en' ? role.ou_en : role.ou_nb}
      </TableCell>
    </TableRow>
  )
}

export default RoleLine
