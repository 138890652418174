import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { format } from 'date-fns'
import { addDays } from 'date-fns/fp'
import { DatePicker } from '@mui/lab'
import {
  Checkbox,
  Button,
  Select,
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Stack,
  TextField,
  SelectChangeEvent,
  FormControlLabel,
  Box,
  Typography,
  FormHelperText,
} from '@mui/material'
import Page from 'components/page'
import { Guest } from 'interfaces'
import useOus, { enSort, nbSort, OuData } from 'hooks/useOus'
import useRoleTypes, { RoleTypeData } from 'hooks/useRoleTypes'
import SponsorInfoButtons from 'routes/components/sponsorInfoButtons'
import { submitJsonOpts } from 'utils'
import { useFeatureContext } from 'contexts/featureContext'
import { availableInSearchEnabled } from 'appConfig'

import SubmitState from '../../register/submitState'
import ServerErrorReport, {
  ServerErrorReportData,
} from '../../../../components/errorReport'

type AddRoleFormData = {
  orgunit?: number
  type: string
  end_date: Date
  start_date: Date
  contact_person_unit?: string
  comments?: string
  available_in_search?: boolean
}
type AddRolePayload = {
  orgunit?: number
  person: string
  type: string
  end_date: string
  start_date?: string
  contact_person_unit?: string
  comments?: string
  available_in_search?: boolean
}

type GuestInfoParams = {
  pid: string
}

interface NewGuestRoleProps {
  guest: Guest
  reloadGuestInfo: () => void
}

function NewGuestRole({ guest, reloadGuestInfo }: NewGuestRoleProps) {
  const [submitState, setSubmitState] = useState(SubmitState.NotSubmitted)

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<AddRoleFormData>({ mode: 'onTouched' })
  const { displayContactAtUnit, displayComment } = useFeatureContext()

  const { pid } = useParams<GuestInfoParams>()
  // Hack to make typecheck happy. The only way for pid to be undefined
  // is if the Route is set up wrong, which should brake everything any way
  if (typeof pid === 'undefined') {
    return <></>
  }
  const navigate = useNavigate()

  const { ous } = useOus()
  const roleTypes = useRoleTypes()
  const [ouChoice, setOuChoice] = useState<string>('')
  const [roleTypeChoice, setRoleTypeChoice] = useState<string>('')
  const [t, i18n] = useTranslation('common')
  const today = new Date()
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [maxDate, setMaxDate] = useState(today)
  const [submitErrorReport, setSubmitErrorReport] =
    useState<ServerErrorReportData>()

  const todayPlusMaxDays = (roleTypeId?: number) => {
    if (roleTypeId) {
      const role =
        roleTypes === undefined
          ? undefined
          : roleTypes.find((rt) => rt.id === roleTypeId)
      if (role !== undefined) {
        return addDays(role.max_days)(today)
      }
    }
    return addDays(0)(today)
  }

  const roleTypeSort = () => (a: RoleTypeData, b: RoleTypeData) => {
    if (i18n.language === 'en') {
      return a.name_nb.localeCompare(b.name_nb)
    }
    return a.name_en.localeCompare(b.name_en)
  }
  // Handling choices in menus
  const handleRoleTypeChange = (event: SelectChangeEvent) => {
    setValue('type', event.target.value)
    setRoleTypeChoice(event.target.value)
    setMaxDate(todayPlusMaxDays(Number(event.target.value)))
  }
  const handleOuChange = (event: any, value: any) => {
    if (value.id) {
      setOuChoice(value.id)
      setValue('orgunit', parseInt(value.id, 10))
    } else {
      setValue('orgunit', undefined)
    }
  }
  // Functions for menu items
  const rolesToItem = (roleType: RoleTypeData) => (
    <MenuItem key={roleType.id.toString()} value={roleType.id}>
      {i18n.language === 'en' ? roleType.name_en : roleType.name_nb}
    </MenuItem>
  )

  function handleSubmitErrorResponse(res: Response) {
    // Try to extract data from body of error message
    res
      .text()
      .then((text) => {
        setSubmitState(SubmitState.SubmitFailure)

        // Some error responses have a code that is used to look up a translated text.
        // Attempt to parse the message text and extract the code
        const jsonResponse = JSON.parse(text)
        const errorText =
          jsonResponse.code === undefined
            ? text
            : t(`error.codes.${jsonResponse.code}`)

        setSubmitErrorReport({
          errorHeading: t('error.invitationCreationFailedHeader'),
          statusCode: res.status,
          statusText: res.statusText,
          errorBodyText: errorText,
        })
      })
      .catch(() => {
        // Extracting data from body failed, just show an error message with no body text
        setSubmitErrorReport({
          errorHeading: t('error.invitationCreationFailedHeader'),
          statusCode: res.status,
          statusText: res.statusText,
          errorBodyText: undefined,
        })
        setSubmitState(SubmitState.SubmitFailure)
      })
  }

  function getFullOptionLabel(ouData: OuData) {
    switch (i18n.language) {
      case 'en':
        return `${ouData.en}${ouData.name_short ? ` (${ouData.name_short})` : ''
          }${ouData.legacy_stedkode ? ` (${ouData.legacy_stedkode})` : ''}`

      case 'nn':
        return `${ouData.nb}${ouData.name_short ? ` (${ouData.name_short})` : ''
          }${ouData.legacy_stedkode ? ` (${ouData.legacy_stedkode})` : ''}`
      default:
        // There should always be a Norwegian bokmaal acronym set

        return `${ouData.nb}${ouData.name_short ? ` (${ouData.name_short})` : ''
          }${ouData.legacy_stedkode ? ` (${ouData.legacy_stedkode})` : ''}`
    }
  }

  const postRole = async (formData: AddRoleFormData) => {
    const payload: AddRolePayload = {
      orgunit: formData.orgunit,
      person: pid,
      type: formData.type,
      end_date: format(formData.end_date as Date, 'yyyy-MM-dd'),
    }
    if (formData.start_date) {
      payload.start_date = format(formData.start_date as Date, 'yyyy-MM-dd')
    }
    if (formData.contact_person_unit) {
      payload.contact_person_unit = formData.contact_person_unit
    }
    if (formData.comments) {
      payload.comments = formData.comments
    }
    if (availableInSearchEnabled && formData.available_in_search) {
      payload.available_in_search = formData.available_in_search
    }
    fetch('/api/ui/v1/role', submitJsonOpts('POST', payload))
      .then((res) => {
        reloadGuestInfo()
        if (!res.ok) {
          handleSubmitErrorResponse(res)
        } else {
          setSubmitState(SubmitState.SubmitSuccess)
          navigate(`/sponsor/guest/${pid}`)
        }
      })
      .catch(() => {
        setSubmitState(SubmitState.SubmitFailure)
      })
  }
  const onSubmit = handleSubmit(async () => {
    postRole(getValues())
  })

  const hasRoleTypeError =
    errors && errors.type && errors.type.type === 'required'
  const hasOuChoiceError =
    errors && errors.orgunit && errors.orgunit.type === 'required'
  return (
    <Page>
      <SponsorInfoButtons
        to={`/sponsor/guest/${pid}`}
        name={`${guest.first} ${guest.last}`}
      />
      <Typography sx={{ marginBottom: '1rem' }} variant="h2">
        {t('guest.headerText')}
      </Typography>
      <Typography sx={{ marginBottom: '1rem' }} variant="body1">
        {t('guest.bodyText')}
      </Typography>
      <form onSubmit={onSubmit}>
        <Stack spacing={2}>
          <Controller
            name="type"
            control={control}
            rules={{ required: true }}
            render={() => (
              <FormControl error={hasRoleTypeError}>
                <InputLabel id="roletype-select-label">
                  {t('input.roleType')}
                </InputLabel>
                <Select
                  id="roletype-select"
                  defaultValue=""
                  value={roleTypeChoice}
                  error={!!errors.type}
                  label={t('input.roleType')}
                  onChange={handleRoleTypeChange}
                >
                  {(roleTypes === undefined ? [] : roleTypes)
                    .sort(roleTypeSort())
                    .map((rt) => rolesToItem(rt))}
                </Select>
              </FormControl>
            )}
          />
          {hasRoleTypeError && (
            <FormHelperText error>
              {t('validation.typeMustBeChosen')}
            </FormHelperText>
          )}

          <Controller
            name="orgunit"
            control={control}
            rules={{ required: true }}
            render={() => (
              <Autocomplete
                autoHighlight
                disabled={ous === undefined}
                value={
                  ouChoice && ous
                    ? ous.find((ou) => ou.id.toString() === ouChoice.toString())
                    : null
                }
                options={(ous === undefined ? [] : ous).sort(
                  i18n.language === 'en' ? enSort : nbSort
                )}
                getOptionLabel={(option) => getFullOptionLabel(option)}
                onChange={handleOuChange}
                renderInput={(param) => (
                  <TextField {...param} label={t('common:ou')} />
                )}
              />
            )}
          />
          {hasOuChoiceError && (
            <FormHelperText error>
              {t('validation.ouMustBeChosen')}
            </FormHelperText>
          )}

          <Controller
            name="start_date"
            control={control}
            rules={{
              required: true,
              validate: () =>
                Number(getValues('start_date')) <=
                Number(getValues('end_date')),
            }}
            defaultValue={today}
            render={({ field }) => (
              <DatePicker
                mask="____-__-__"
                label={t('input.roleStartDate')}
                disabled={!roleTypeChoice}
                value={field.value}
                maxDate={maxDate}
                inputFormat="yyyy-MM-dd"
                onChange={(value) => {
                  field.onChange(value)
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            )}
          />

          {errors.start_date && errors.start_date.type === 'required' && (
            <Box sx={{ typography: 'caption', color: 'error.main' }}>
              {t('validation.startDateMustBeSet')}
            </Box>
          )}
          {errors.start_date && errors.start_date.type === 'validate' && (
            <Box sx={{ typography: 'caption', color: 'error.main' }}>
              {t('validation.startDateMustBeBeforeEndDate')}
            </Box>
          )}
          <Controller
            name="end_date"
            control={control}
            rules={{
              required: true,
              validate: () => Number(getValues('end_date')) <= Number(maxDate),
            }}
            render={({ field }) => (
              <DatePicker
                mask="____-__-__"
                label={t('input.roleEndDate')}
                disabled={!roleTypeChoice}
                value={endDate}
                minDate={today}
                maxDate={maxDate}
                inputFormat="yyyy-MM-dd"
                onChange={(value) => {
                  setEndDate(value)
                  field.onChange(value)
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            )}
          />
          {errors.end_date && errors.end_date.type === 'required' && (
            <Box sx={{ typography: 'caption', color: 'error.main' }}>
              {t('validation.roleEndRequired')}
            </Box>
          )}
          {errors.end_date && errors.end_date.type === 'validate' && (
            <Box sx={{ typography: 'caption', color: 'error.main' }}>
              {t('validation.invalidEndDate')}
            </Box>
          )}
          {displayContactAtUnit && (
            <TextField
              id="contact"
              label={t('input.contactPersonUnit')}
              multiline
              rows={5}
              {...register('contact_person_unit')}
            />
          )}
          {displayComment && (
            <TextField
              id="comments"
              label={t('input.comment')}
              multiline
              rows={5}
              {...register('comments')}
            />
          )}
          {availableInSearchEnabled && (
            <FormControlLabel
              control={
                <Checkbox
                  id="available_in_search"
                  {...register('available_in_search')}
                />
              }
              label={t('input.searchable')}
            />
          )}
          <Button variant="contained" color="secondary" type="submit">
            {t('button.save')}
          </Button>
          <Button
            component={Link}
            color="secondary"
            to={`/sponsor/guest/${pid}`}
          >
            {t('button.cancel')}
          </Button>
          {submitState === SubmitState.SubmitFailure &&
            submitErrorReport !== undefined && (
              <ServerErrorReport
                errorHeading={submitErrorReport?.errorHeading}
                statusCode={submitErrorReport?.statusCode}
                statusText={submitErrorReport?.statusText}
                errorBodyText={submitErrorReport?.errorBodyText}
              />
            )}
        </Stack>
      </form>
    </Page>
  )
}

export default NewGuestRole
